import {
  CameraStates,
  THREE
} from '@powerplay/core-minigames'

/*
 * pozicia poslednej branky pre config veci nizsie
 * const lastGate = batchingConfig.get('Branka0')
 * const lastGateData = lastGate ? lastGate.data : [{ offset: new THREE.Vector3() }]
 * const lastGatePosition = lastGateData[lastGateData.length - 1].offset.clone()
 */

/**
 * Konfig pre kameru pre vsetky stavy
 */
export const cameraConfig = {

  /** Nastavenie pozicii a pohybov kamery pre vsetky stavy */
  tweenSettingsForCameraStates: {
    [CameraStates.intro]: {
      startPosition: new THREE.Vector3(619.9957275390625, -374.0755920410156, -3282.29345703125),
      endPosition: new THREE.Vector3(726.3755493164, -459.3713073730469, -3449.3896484375),
      startRotation: new THREE.Vector3(1.375850785721479, -0.187495385823295, 0.3282932869867339),
      endRotation: new THREE.Vector3(1.0376702417092367, 0.1810736820950996, 0.4348128878125388),
      duration: 5,
      notSkippableFrames: 20,
      fov: 62
    },
    [CameraStates.intro2]: {
      startPosition: new THREE.Vector3(745.3016357421875, -588.1825561523438, -3728.642822265625),
      endPosition: new THREE.Vector3(774.5447998046875, -589.1390380859375, -3739.221435546875),
      startRotation: new THREE.Vector3(1.4187218473582528, 0.00913614429937621, -0.1345846820147521),
      endRotation: new THREE.Vector3(1.4492753111432441, 0.04282379154070754, -0.42577979262518),
      duration: 3,
      notSkippableFrames: 20,
      fov: 81.2
    },
    [CameraStates.table]: {
      startPosition: new THREE.Vector3(737.243380827, -587.20243848552, -3785.9517458338482),
      startLookAt: new THREE.Vector3(733.8574450655736, -619.625877085, -3849.3641920064742),
      endPosition: new THREE.Vector3(743.3601725911724, -587.20243848552, -3789.22183279171),
      duration: 5
    },
    [CameraStates.disciplineIntro]: [
      {
        startPosition: new THREE.Vector3(660.249816894531, -240.57708740234375, -2860.26708984375),
        endPosition: new THREE.Vector3(659.3060913085938, -235.69699096679688, -2842.8515625),
        startRotation: new THREE.Vector3(1.2543071300031796, 0.15493268325629495, -3.1013759178094524),
        endRotation: new THREE.Vector3(1.2525969835783923, -0.134602140800898, 3.0898466286476194),
        duration: 3,
        fov: 81.2
      },
      {
        startPosition: new THREE.Vector3(660.1421508789, -232.0947265625, -2827.83642578125),
        endPosition: new THREE.Vector3(659.257568359375, -232.0947265625, -2827.855224609375),
        startRotation: new THREE.Vector3(1.4362823610543831, 0.02024620162849547, -0.1484665915798281),
        endRotation: new THREE.Vector3(1.4405164480958637, -0.011171189793070274, 0.08508234241370864),
        duration: 3,
        fov: 81.2
      }
    ],
    [CameraStates.disciplineIntroSecond]: undefined,
    [CameraStates.discipline]: undefined,
    [CameraStates.disciplineOutro]: {
      startPosition: new THREE.Vector3(3, 1.5, -3),
      endPosition: new THREE.Vector3(-3, 1.5, -3),
      duration: 6,
      offsetCamera: true
    },
    [CameraStates.static]: undefined
  },
  distanceFromGround: 1.5,

  /** Koeficient pre lookAt kamery */
  tLookAtCoef: 0.01

}
