import { player } from '../player'
import {
  AudioGroups,
  AudioNames,
  type DisciplinePhaseManager,
  PlayerAnimationsNames
} from '../types'
import store from '@/store'
import {
  batchingConfig,
  finishPhaseConfig,
  gameConfig
} from '../config'
import {
  timeManager,
  playersManager,
  THREE,
  fpsManager,
  game,
  CameraStates,
  modes,
  trainingManager,
  corePhasesManager,
  gsap,
  audioManager,
  minigameConfig,
  type BatchingData,
  CANNON,
  cameraManager,
  type CannonNamedBody,
  type CollisionEvent,
  PlayersSortTypes
} from '@powerplay/core-minigames'
import { trainingTasks } from '../modes/training/TrainingTasks'
import { endManager } from '../EndManager'
import { tutorialFlow } from '../modes/tutorial/TutorialFlow'
import { tutorialObjectives } from '../modes/tutorial/TutorialObjectives'
import { inputsManager } from '../InputsManager'
import { gatesManager } from '../GatesManager'
import { SplitTimeManager } from '../SplitTimeManager'

/**
 * Trieda fazy pre dojazd v cieli (resp naburanie)
 */
export class FinishPhaseManager implements DisciplinePhaseManager {

  /** pocet zle prejdenych branok */
  #successfulGates = 0

  /** Ci bol exit game */
  public isPrematureExit = false

  /** callback na zavolanie po skonceni fazy */
  public callbackEnd: () => unknown

  /** tween na ukoncenie fazy po animacii */
  public finishPhaseTween !: gsap.core.Tween

  /** Frame in animation */
  private frameInPhase = 0

  /** Uhol otocenia */
  private angle = 0

  /** ci faza skoncila */
  private ended = false

  /** Normalizovany vektor dopredu */
  private normalizedVectorForward = new THREE.Vector3()

  /** Fyzicke bodicka stran */
  private physicsBodies: CANNON.Body[] = []

  /** Uhol roviny, lebo nie je to v ramci jednej osi */
  private planeAngle = 0

  /** Aktualna rychlost pohybu */
  private actualSpeed = 0

  /** Time of final animation */
  private FINAL_ANIMATION_TIME = finishPhaseConfig.endActionCurveTime * fpsManager.fpsLimit

  /** ci je mozne skipnut */
  private skippable = false

  /** kolko sekunt po zaciatku je mozne skipnut */
  private skipDelay = 3

  /** tween na nastavenie skippable */
  private skippableTween !: gsap.core.Tween

  /** tween na zmenu UI stavu */
  private changeUiStateTween!: gsap.core.Tween

  /** kolko po starte mame zobrazit finish top box */
  private SHOW_FINISH_TOP_BOX_SECONDS = 2

  /**
   * Konstruktor
   */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * getter
   */
  public get successfulGates(): number {

    return this.#successfulGates

  }

  /**
   * setter
   */
  public set successfulGates(newValue: number) {

    this.#successfulGates = newValue

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase = (): void => {

    // ulozime si posledne 2 ulohy v treningu
    trainingTasks.saveLastTasksValues()

    // Zmena animacie pre pripad vacsej rychlosti
    player.activeUpdatingMovementAnimations = false
    this.calculateDataForFinish()
    const velocity = player.velocityManager.getVelocity()
    this.actualSpeed = Math.sqrt((velocity.x ** 2) + (velocity.y ** 2) + (velocity.z ** 2)) /
            fpsManager.fpsLimit
    console.log(`Finish speed ${this.actualSpeed}`)

  }

  /**
   * Vypocitanie dat pre ciel
   */
  private calculateDataForFinish(): void {

    const batchingDataLeft = batchingConfig.get('Branka1LEFT')
    const batchingDataRight = batchingConfig.get('Branka1RIGHT')
    if (!batchingDataLeft || !batchingDataRight) throw new Error('Batching data is missing !')

    const leftPoint = batchingDataLeft.data[batchingDataLeft.data.length - 1]
    const rightPoint = batchingDataRight.data[batchingDataRight.data.length - 1]

    // najskor vypocitame vzdialenost medzi lavym a pravym bodom, tj preponu trojuholnika
    const a = Math.abs(leftPoint.offset.z - rightPoint.offset.z)
    const b = Math.abs(leftPoint.offset.x - rightPoint.offset.x)
    const c = Math.sqrt(a ** 2 + b ** 2)

    // ked mame preponu, mozeme vypocitat uhly
    const alpha = Math.asin(b / c)
    this.planeAngle = (Math.PI / 2) - alpha - (Math.PI / 2)
    console.log(`Plane angle ${this.planeAngle}`)

    /*
     * teraz vieme, ze uhol o ktory otacame priestor (kedze to nie je rovina) je o uhol beta
     * spravime si normalizovany vektor
     */
    this.setNormalizedVectorForwardByAngle()
    this.createTriggers(leftPoint, rightPoint)

    console.log('normalizovany vektor je ', this.normalizedVectorForward)

  }

  /**
   * Taka metoda co vytvori take dva triggere o ktore ak collidneme tak potom otocime hraca
   * @param leftPoint - BatchingData lavy trigger
   * @param rightPoint - BarchingData pravy trigger
   */
  private createTriggers(leftPoint: BatchingData, rightPoint: BatchingData): void {

    const width = finishPhaseConfig.PHYSICS_WIDTH
    const height = finishPhaseConfig.PHYSICS_HEIGHT
    const depth = finishPhaseConfig.PHYSICS_DEPTH
    const shapes: CANNON.Box[] = []
    for (let i = 0; i <= 1; i++) {

      const shape = new CANNON.Box(new CANNON.Vec3(width, height, depth))
      shapes.push(shape)
      const physicsBody = new CANNON.Body({
        mass: 0,
        shape,
        material: new CANNON.Material('Trigger'),
        isTrigger: true,
        collisionFilterGroup: 2,
        collisionFilterMask: 2
      }) as CannonNamedBody
      physicsBody.name = `finish-hranica-${i}`

      this.physicsBodies.push(physicsBody)
      game.physics.addBody(physicsBody)

      physicsBody.type = CANNON.BODY_TYPES.STATIC

      physicsBody.quaternion.setFromAxisAngle(new CANNON.Vec3(0, 1, 0), this.planeAngle)
      if (i === 0) {

        physicsBody.position.set(
          leftPoint.offset.x,
          leftPoint.offset.y,
          leftPoint.offset.z
        )

      } else {

        physicsBody.position.set(
          rightPoint.offset.x,
          rightPoint.offset.y,
          rightPoint.offset.z
        )

      }
      const vec3 = new CANNON.Vec3(
        this.normalizedVectorForward.x,
        this.normalizedVectorForward.y,
        this.normalizedVectorForward.z
      )

      physicsBody.position.addScaledVector(width / 2, vec3)

      physicsBody.addEventListener('collide', (e: CollisionEvent) => {

        if (e.body.name?.includes('collisionPlayerBody')) {

          const target = e.target.name || 'nemameno'
          this.collisionResolve(target, e.body.name)

        }

      })

    }

  }

  /**
   * Vyratanie normalizovaneho vektora podla uhla
   * @param angle - Uhol posunu
   */
  private setNormalizedVectorForwardByAngle(angle = 0): void {

    const changedAngle = this.planeAngle + angle - Math.PI / 2

    this.normalizedVectorForward.set(
      -Math.sin(changedAngle), // akoby toto * 1
      0,
      Math.cos(changedAngle), // akoby toto * 1
    )

  }

  /**
   * Start fazy
   */
  public startPhase = (): void => {

    console.warn('finish phase started')
    fpsManager.pauseCounting()
    audioManager.stopAudioByName(AudioNames.skiingCorner)

    this.reset()
    this.preparePhase()

    store.commit('InputsState/SET_VISIBLE', false)
    store.commit('ActionButtonState/SET_SHOW_MOVEMENT_CONTROL', false)

    playersManager.setPlayerResults(timeManager.getGameTimeWithPenaltyInSeconds(true))
    playersManager.setStandings()
    console.log('STANDINGS', playersManager.getStandings())
    store.commit('TableState/SET_DATA', playersManager.getStandings())

    // Po prejdeni cielom dame animaciu brzdenia.
    player.endAnimation(this.getEndEmotion())

    store.commit('TrainingState/SET_HIGH_SCORE', {
      newHighScore: Math.ceil(trainingManager.getNewPotentialHighScore()),
      showNewHighScore: trainingManager.isNewHighScore()
    })

    if (modes.isTutorial() && !tutorialObjectives.checkIfAllObjectivesPassed()) {

      tutorialFlow.finishAction()
      return

    }

    store.commit('UiState/SET_STATE', {
      showTimeKeeper: !(modes.isTutorial() || modes.isTrainingMode()),
      showSplitTimes: false,
      showFinishTopBox: false,
      showTrainingLayout: modes.isTrainingMode(),
      isTraining: modes.isTrainingMode()
    })

    this.changeUiStateTween = gsap.to({}, {
      duration: this.SHOW_FINISH_TOP_BOX_SECONDS,
      onComplete: () => {

        store.commit('UiState/SET_STATE', {
          showTimeKeeper: false,
          showSplitTimes: false,
          showFinishTopBox: (!modes.isTutorial() && !modes.isTrainingMode()),
          showTrainingLayout: modes.isTrainingMode(),
          isTraining: modes.isTrainingMode()
        })
        this.setFinishTopBoxData()

      }
    })

    if (modes.isTutorial()) return

    player.finishAction()

    cameraManager.setState(CameraStates.disciplineOutro)
    cameraManager.playTween()

    // data pre tabulku
    this.setDataForPositionsTable()
    store.commit(
      'GameplayTableState/SET_TABLES_VISIBILITY',
      {
        showTables: true,
        showLeftTable: true,
        showRightTable: true
      }
    )

    this.playCommentatorAudio()

    this.setSkippableTween()

  }

  /**
   * nastavime tween ktorym umoznime skipp
   */
  private setSkippableTween(): void {

    this.skippableTween = gsap.to({}, {
      duration: this.skipDelay,
      onComplete: () => {

        this.skippable = true

      }
    })

  }

  /**
   * zahrame audio komentatora
   */
  private playCommentatorAudio(): void {

    const rank = playersManager.getPlayerActualPosition()

    let audio = AudioNames.commentFinish4

    if (rank === 1) {

      audio = AudioNames.commentFinish1

    } else if (rank <= 3) {

      audio = AudioNames.commentFinish2

    } else if (rank <= (modes.isDailyLeague() || modes.isBossCompetition() ? 10 : 5)) {

      audio = AudioNames.commentFinish3

    }

    audioManager.stopAudioByGroup(AudioGroups.commentators)
    audioManager.play(audio)

  }

  /**
   * naplnime tabulku pozicii okolo hraca a cas hraca
   */
  private setDataForPositionsTable(): void {

    const data = playersManager.getSimilarPositionPlayersForTable()
    const tableData = []
    let playerResultString = ''

    for (let i = 0; i < data.length; i++) {

      if (data[i] === undefined) continue

      tableData.push({
        position: data[i].position,
        country: data[i].country,
        countryString: data[i].countryString,
        player: {
          name: data[i].name,
          isPlayer: data[i].playable
        },
        time: data[i].result,
        timeDiff: data[i].result,
        isBonus: false
      })

      if (data[i].playable) {

        playerResultString = data[i].result || ''

      }

    }
    store.commit(
      'GameplayTableState/SET_TABLE_DATA',
      tableData
    )

    const playerData = playersManager.getPlayer()

    console.log('Finalny cas', timeManager.getGameTimeWithPenaltyInSeconds(true))
    store.commit('GameplayTableState/SET_PLAYER_DATA', {
      position: playersManager.getPlayerActualPosition(),
      country: playerData.country,
      countryString: playerData.countryString,
      player: {
        name: playerData.name,
        isPlayer: true
      },
      time: timeManager
        .getTimeInFormatFromSeconds(playerData.resultsArr?.[corePhasesManager.disciplineActualAttempt - 1].main || 0),
      timeDiff: playerResultString,
      isBonus: !playerResultString.includes('+')
    })
    const bestTime = playersManager
      .getBestResultPlayerInfo(PlayersSortTypes.ascending, true).finalResult ?? minigameConfig.dnfValue

    const dnfException = bestTime === minigameConfig.dnfValue

    const actualTime = playerData.resultsArr?.[
      corePhasesManager.disciplineActualAttempt - 1
    ].main ?? minigameConfig.dnfValue

    const difference = actualTime - (bestTime ?? actualTime)
    const differencePrefix = SplitTimeManager.getDifferencePrefix(difference)
    const differenceText = dnfException ?
      '' :
      SplitTimeManager.formatDifferenceTime(difference, differencePrefix)

    store.commit(
      'SplitTimeState/ADD_SPLIT_TIME',
      { text: differenceText,
        color: SplitTimeManager.getColor(difference) }
    )

    const timeSeconds = playersManager.getPlayer().resultsArr?.[
      corePhasesManager.disciplineActualAttempt - 1
    ].main || 0

    store.commit('TimeState/SET_SHOW_BOOLEANS', {
      v2Expanded: false,
      showDiff: !dnfException,
      diffIndex: gatesManager.splitTimeManager.getSplitCount(),
      isV1: false,
      doTimeUpdate: false,
      bestTime: timeSeconds,
      isFinish: true
    })

  }

  /**
   * Aktualizovanie fazy
   */
  public update = (): void => {

    this.forwardPlayer()
    this.skipPhase()

  }

  /**
   * Metoda na posunutie playera
   */
  private forwardPlayer(): void {

    this.frameInPhase++

    const speed = this.getSpeed()

    if (this.frameInPhase >= this.FINAL_ANIMATION_TIME * 0.45) {

      this.setNormalizedVectorForwardByAngle(this.angle)

    } else if (this.frameInPhase >= this.FINAL_ANIMATION_TIME * 0.3) {

      // potom mierny obluk vpravo pocas druhych 25% animacie a na zaver rovna ciara.
      this.angle += finishPhaseConfig.ROTATION_MIDDLE_PER_FRAME
      this.setNormalizedVectorForwardByAngle(this.angle)
      audioManager.stopAudioByName(AudioNames.skiingBreak)

    } else {

      // Trajektoria bude mat mierny obluk vlavo pocas prvych 25% animacie,
      this.angle += finishPhaseConfig.ROTATION_BEGINNING_PER_FRAME
      this.setNormalizedVectorForwardByAngle(this.angle)

    }

    const vec3 = new CANNON.Vec3(
      this.normalizedVectorForward.x,
      this.normalizedVectorForward.y,
      this.normalizedVectorForward.z
    )

    player.physicsBody.position = player
      .physicsBody.position.addScaledVector(speed, vec3)

    const forwardLook = player
      .physicsBody.position.addScaledVector(speed + 0.1, vec3)
    player.playerObject.lookAt(
      forwardLook.x,
      player.playerObject.position.y,
      forwardLook.z
    )
    player.physicsBody.position.y -=
            (player.intersectionDistance - gameConfig.playerModelOffset)
    player.playerObject.position.y -=
            (player.intersectionDistance - gameConfig.playerModelOffset)

  }

  /**
   * Metoda ktora spomaluje
   */
  private getSpeed(): number {

    // pri velmi velkych rychlostiach extra spomalujeme aby sme nezasli za divakov
    if (this.actualSpeed >= finishPhaseConfig.softSpeedLimit.speed) {

      this.actualSpeed *= finishPhaseConfig.softSpeedLimit.coef

    }

    // Rychlost lyziara budena zaciatku rovnaka, aku mal tesne pred cielom.
    if (this.frameInPhase <= this.FINAL_ANIMATION_TIME * 0.4) {

      // Postupne bude spomalovat tak, aby v polovici animacie mal rychlost 75%,
      const frameCoef = this.frameInPhase / (this.FINAL_ANIMATION_TIME * 0.5)
      const speedCoefToSlow = this.actualSpeed * finishPhaseConfig.ANIMATION_SPEED_IN_HALF
      return this.actualSpeed - (speedCoefToSlow * frameCoef)

    }

    if (this.frameInPhase <= this.FINAL_ANIMATION_TIME * 0.7) {

      // v ¾ animacie bude mat rychlost 50%
      const frameCoef = this.frameInPhase / (this.FINAL_ANIMATION_TIME * 0.75)
      const speedCoefToSlow = this.actualSpeed * finishPhaseConfig.ANIMATION_SPEED_IN_THIRD
      return this.actualSpeed - (speedCoefToSlow * frameCoef)

    }

    // a na konci animacie uplne zastavi.
    const frameCoef = this.frameInPhase / this.FINAL_ANIMATION_TIME * 1.2
    const speedCoefToSlow = this.actualSpeed
    const speed = this.actualSpeed - (speedCoefToSlow * frameCoef)
    return speed <= 0 ? 0 : speed

  }

  /**
   * Metoda na fix kolizie
   * @param targetName - meno targetu
   * @param bodyName - meno bodycka
   */
  private collisionResolve(targetName: string, bodyName: string): void {

    const nameOfCollisionObject = targetName.includes('inish-hranica') ? targetName : bodyName
    const leftCollision = nameOfCollisionObject.includes('0')

    if (leftCollision) {

      // naraz  vpravo
      this.angle = -1

    } else {

      // naraz  vlavo
      this.angle = 0

    }

  }

  /**
   * Predcasne ukoncenie fazy pri stlaceni akcie
   */
  public skipPhase(): void {

    if (!this.skippable || !inputsManager.actionPressed) return

    this.finishPhase()

  }

  /**
   * nastavime data pre top box
   */
  private setFinishTopBoxData(): void {

    if (modes.isDailyLeague() && !playersManager.isPlayerImproved()) return

    const timeSeconds = timeManager.getGameTimeWithPenaltyInSeconds(true)
    const personalBest = playersManager.getPlayer().personalBest
    const timeFormat = timeManager.getTimeInFormatFromSeconds(timeSeconds)
    const position = playersManager.getPlayerActualPosition()

    const showFirstBox = position < 4
    const showSecondBox = timeSeconds <= personalBest

    store.commit('FinishTopBoxState/SET_STATE', {
      showFirstBox: showFirstBox,
      showSecondBox: showSecondBox,
      firstPlace: position === 1,
      personalBest: timeSeconds === personalBest,
      newPersonalBest: timeSeconds < personalBest,
      time: timeFormat,
      position: position
    })

  }

  /**
   * Ukoncene fazy
   * @param type - Typ ukoncenia
   */
  public finishPhase = (): void => {

    if (this.ended) return
    this.ended = true

    if (this.finishPhaseTween) this.finishPhaseTween.kill()
    if (this.skippableTween) this.skippableTween.kill()
    if (this.changeUiStateTween) this.changeUiStateTween.kill()

    store.commit(
      'GameplayTableState/SET_TABLES_VISIBILITY',
      {
        showTables: false
      }
    )

    store.commit('UiState/SET_STATE', {
      showTimeKeeper: false,
      showSplitTimes: false,
      showFinishTopBox: (!modes.isTutorial() && !modes.isTrainingMode()),
      showTrainingLayout: false,
      isTraining: modes.isTrainingMode()
    })

    fpsManager.pauseCounting()
    endManager.sendLogEnd()
    endManager.sendSaveResult()

    console.warn('finish phase ended')
    this.callbackEnd()

    audioManager.stopAudioByName(AudioNames.audienceHype)
    game.togglePhysics(false)

  }

  /**
   * sets tween to finish phase
   */
  public setFinishPhaseTween(): void {

    if (this.finishPhaseTween) this.finishPhaseTween.kill()

    this.finishPhaseTween = gsap.to({}, {
      duration: 1,
      onComplete: () => this.finishPhase(),
      callbackScope: this
    })

  }

  /**
   * Vratenie konecnej emocie
   * @returns Emocia
   */
  private getEndEmotion = (): PlayerAnimationsNames => {

    if (modes.isTrainingMode()) return this.getTrainingEndEmotion()

    const time = timeManager.getGameTimeWithPenaltyInSeconds(true)
    const personalBest = playersManager.getPlayer().personalBest
    const isPersonalBest = time < personalBest
    const position = playersManager.getPlayerActualPosition()
    const playerCount = playersManager.players.length
    const badPositions = Math.ceil(playerCount - (playerCount * 0.3))

    if (position <= 3 || isPersonalBest) return PlayerAnimationsNames.happy
    if (position >= badPositions) return PlayerAnimationsNames.bad

    return PlayerAnimationsNames.neutral

  }

  /**
   * Vratenie konecne emocie v treningu
   * @returns Emocia
   */
  private getTrainingEndEmotion(): PlayerAnimationsNames {

    const tasks = trainingManager.getTrainingTasks()
    const sum = tasks.reduce((prev, current) => prev + current.value, 0)
    const average = sum / tasks.length

    // default je 0-1 hviezd
    let animation = PlayerAnimationsNames.bad

    // ak 2 hviezdy
    if (average > 0.75) animation = PlayerAnimationsNames.neutral

    // ak 3 hviezdy
    if (average > 0.9) animation = PlayerAnimationsNames.happy

    return animation

  }

  /**
   * reset fazy
   */
  public reset(): void {

    this.successfulGates = 0
    if (this.skippableTween) this.skippableTween.kill()
    if (this.changeUiStateTween) this.changeUiStateTween.kill()

  }

}
