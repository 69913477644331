import {
  type BatchingObjectData,
  THREE
} from '@powerplay/core-minigames'

/**
 * Trieda pre konfig batching objektov
 */
export const batchingConfig: Map<string, BatchingObjectData> = new Map([
  ['Branka1RIGHT', {
    name: 'Branka1RIGHT',
    data: [
      {
        offset: new THREE.Vector3(679.03, -247.81, -2877.98),
        rotation: new THREE.Vector3(3.08, -0.04, 3.12),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(676.82, -263.43, -2922.00),
        rotation: new THREE.Vector3(2.93, 0.00, -3.11),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(658.88, -280.11, -2953.64),
        rotation: new THREE.Vector3(3.00, 0.49, -3.11),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(681.06, -299.52, -2991.78),
        rotation: new THREE.Vector3(2.76, 0.76, -3.00),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(704.97, -314.99, -3037.64),
        rotation: new THREE.Vector3(3.02, 0.23, -3.14),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(704.90, -324.95, -3087.50),
        rotation: new THREE.Vector3(3.10, -0.03, 3.05),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(716.41, -332.71, -3128.51),
        rotation: new THREE.Vector3(3.07, -0.02, 3.02),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(704.12, -348.60, -3181.04),
        rotation: new THREE.Vector3(3.08, -0.33, 3.11),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(673.17, -363.55, -3225.83),
        rotation: new THREE.Vector3(2.85, -0.57, 3.09),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(656.49, -379.64, -3266.43),
        rotation: new THREE.Vector3(2.88, -0.15, -3.10),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(650.86, -398.12, -3314.61),
        rotation: new THREE.Vector3(2.95, -0.07, -3.03),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(657.31, -417.06, -3358.56),
        rotation: new THREE.Vector3(2.69, 0.27, -3.00),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(673.70, -439.24, -3402.45),
        rotation: new THREE.Vector3(2.93, 0.42, -3.07),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(688.77, -456.81, -3437.38),
        rotation: new THREE.Vector3(2.73, 0.75, -2.94),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(727.82, -477.94, -3465.08),
        rotation: new THREE.Vector3(2.88, 0.86, 3.14),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(766.58, -496.83, -3494.82),
        rotation: new THREE.Vector3(3.09, 0.91, 2.83),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(793.95, -509.84, -3540.09),
        rotation: new THREE.Vector3(2.95, 0.46, 2.92),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(806.04, -522.98, -3591.45),
        rotation: new THREE.Vector3(3.08, 0.03, 2.99),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(795.13, -543.13, -3637.95),
        rotation: new THREE.Vector3(2.87, -0.38, 3.05),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(774.57, -564.44, -3679.28),
        rotation: new THREE.Vector3(2.87, -0.38, 3.05),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(757.73, -584.94, -3723.09),
        rotation: new THREE.Vector3(2.89, -0.19, 3.03),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(746.87, -603.71, -3768.49),
        rotation: new THREE.Vector3(2.90, -0.04, 3.06),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        // cielova branka
        offset: new THREE.Vector3(730.278, -617.705, -3807.142),
        rotation: new THREE.Vector3(2.90, -0.04, 3.03),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      }
    ],
    layer: 0,
    maxVisibleObjects: 5,
    lastVisibleObjectIndex: 0,
    nextIndex: 5
  }],
  ['Branka0RIGHT', {
    name: 'Branka0RIGHT',
    data: [
      {
        offset: new THREE.Vector3(661.29, -241.79, -2858.34),
        rotation: new THREE.Vector3(-3.13, 0.08, 3.07),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(660.71, -254.75, -2899.91),
        rotation: new THREE.Vector3(3.07, -0.04, -3.11),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(673.26, -270.02, -2932.95),
        rotation: new THREE.Vector3(2.94, 0.07, 3.14),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(682.76, -289.14, -2966.32),
        rotation: new THREE.Vector3(2.92, 0.61, -3.07),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(708.63, -307.34, -3005.68),
        rotation: new THREE.Vector3(2.79, 0.55, -3.05),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(723.44, -318.39, -3062.52),
        rotation: new THREE.Vector3(2.95, -0.01, 3.06),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(721.71, -328.70, -3112.83),
        rotation: new THREE.Vector3(3.00, 0.06, 2.98),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(697.06, -341.45, -3152.84),
        rotation: new THREE.Vector3(2.98, 0.04, 3.05),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(676.32, -354.16, -3196.44),
        rotation: new THREE.Vector3(2.88, -0.35, -3.11),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(646.06, -368.96, -3240.15),
        rotation: new THREE.Vector3(2.91, -0.16, -2.94),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(637.01, -386.20, -3289.38),
        rotation: new THREE.Vector3(2.89, -0.02, -2.96),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(636.32, -405.25, -3339.89),
        rotation: new THREE.Vector3(2.83, 0.11, -2.87),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(649.07, -427.01, -3386.97),
        rotation: new THREE.Vector3(2.86, 0.35, -2.93),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(673.69, -450.17, -3429.89),
        rotation: new THREE.Vector3(2.76, 0.74, -2.88),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(721.23, -462.74, -3438.74),
        rotation: new THREE.Vector3(2.73, 0.76, 3.14),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(756.80, -481.25, -3466.52),
        rotation: new THREE.Vector3(2.96, 0.90, 2.81),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(795.04, -499.58, -3509.98),
        rotation: new THREE.Vector3(2.97, 0.53, 2.87),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(815.42, -511.49, -3562.30),
        rotation: new THREE.Vector3(3.11, 0.35, 2.82),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(818.24, -530.88, -3619.53),
        rotation: new THREE.Vector3(2.89, -0.20, 2.95),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(800.16, -554.65, -3666.41),
        rotation: new THREE.Vector3(2.87, -0.38, 3.05),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(779.76, -575.09, -3706.05),
        rotation: new THREE.Vector3(2.87, -0.38, 3.08),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(767.10, -594.32, -3749.01),
        rotation: new THREE.Vector3(2.89, -0.19, 3.07),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(756.41, -610.66, -3795.09),
        rotation: new THREE.Vector3(3.09, -0.06, 3.11),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      }
    ],
    layer: 0,
    maxVisibleObjects: 5,
    lastVisibleObjectIndex: 0,
    nextIndex: 5
  }],
  ['Branka1LEFT', {
    name: 'Branka1LEFT',
    data: [
      {
        offset: new THREE.Vector3(671.24, -247.79, -2877.83),
        rotation: new THREE.Vector3(2.99, -0.05, -3.14),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(668.91, -263.65, -2922.16),
        rotation: new THREE.Vector3(2.81, -0.01, -3.13),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(651.69, -279.96, -2956.44),
        rotation: new THREE.Vector3(2.93, 0.32, -3.05),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(675.55, -300.60, -2997.12),
        rotation: new THREE.Vector3(2.67, 0.73, -2.92),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(697.34, -315.16, -3039.52),
        rotation: new THREE.Vector3(3.04, 0.23, -3.11),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(697.02, -325.70, -3087.31),
        rotation: new THREE.Vector3(3.11, -0.01, 3.06),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(708.79, -333.81, -3128.40),
        rotation: new THREE.Vector3(3.10, -0.02, 3.04),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(696.59, -349.00, -3178.40),
        rotation: new THREE.Vector3(-3.10, -0.33, -3.06),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(666.52, -362.33, -3221.79),
        rotation: new THREE.Vector3(2.94, -0.56, -3.07),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(648.80, -378.59, -3265.01),
        rotation: new THREE.Vector3(2.90, -0.20, -3.07),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(643.15, -396.76, -3313.82),
        rotation: new THREE.Vector3(2.96, -0.10, -2.94),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(649.78, -416.45, -3360.65),
        rotation: new THREE.Vector3(2.65, 0.23, -2.90),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(666.52, -438.99, -3405.32),
        rotation: new THREE.Vector3(2.87, 0.39, -3.03),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(682.80, -457.30, -3442.52),
        rotation: new THREE.Vector3(2.66, 0.71, -2.83),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(722.37, -479.59, -3470.38),
        rotation: new THREE.Vector3(2.79, 0.83, -3.08),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(761.55, -499.26, -3500.35),
        rotation: new THREE.Vector3(2.99, 0.89, 2.96),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(786.82, -511.50, -3542.83),
        rotation: new THREE.Vector3(2.88, 0.44, 3.03),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(798.45, -524.05, -3591.16),
        rotation: new THREE.Vector3(3.02, 0.01, 2.99),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(788.09, -542.84, -3634.62),
        rotation: new THREE.Vector3(2.80, -0.41, 3.03),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(767.50, -563.95, -3676.02),
        rotation: new THREE.Vector3(2.80, -0.41, 3.03),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(750.17, -585.03, -3721.18),
        rotation: new THREE.Vector3(2.82, -0.21, 3.02),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(739.12, -604.13, -3767.65),
        rotation: new THREE.Vector3(2.84, -0.06, 3.06),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        // cielova branka
        offset: new THREE.Vector3(759.919, -617.705, -3813.324),
        rotation: new THREE.Vector3(2.84, -0.06, 3.03),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      }
    ],
    layer: 0,
    maxVisibleObjects: 5,
    lastVisibleObjectIndex: 0,
    nextIndex: 5
  }],
  ['Branka0LEFT', {
    name: 'Branka0LEFT',
    data: [
      {
        offset: new THREE.Vector3(653.51, -242.04, -2859.01),
        rotation: new THREE.Vector3(3.03, 0.07, 3.11),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(652.90, -254.38, -2899.57),
        rotation: new THREE.Vector3(2.97, -0.04, -3.08),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(665.44, -269.72, -2932.57),
        rotation: new THREE.Vector3(2.90, -0.08, -3.10),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(676.47, -289.78, -2970.57),
        rotation: new THREE.Vector3(2.84, 0.57, -3.03),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(701.98, -308.28, -3009.77),
        rotation: new THREE.Vector3(2.82, 0.55, -3.06),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: false
      },
      {
        offset: new THREE.Vector3(715.63, -318.83, -3062.46),
        rotation: new THREE.Vector3(2.97, -0.01, 3.08),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(713.95, -329.75, -3113.27),
        rotation: new THREE.Vector3(3.03, 0.06, 3.01),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(689.12, -341.83, -3153.01),
        rotation: new THREE.Vector3(3.10, 0.03, 3.10),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(668.93, -352.84, -3193.97),
        rotation: new THREE.Vector3(2.99, -0.33, -2.97),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(638.57, -367.17, -3238.25),
        rotation: new THREE.Vector3(2.93, -0.23, -2.95),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(629.39, -384.37, -3289.04),
        rotation: new THREE.Vector3(2.91, -0.06, -2.84),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(628.80, -403.42, -3340.79),
        rotation: new THREE.Vector3(2.81, 0.07, -2.82),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(641.82, -425.73, -3389.60),
        rotation: new THREE.Vector3(2.82, 0.31, -2.81),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(667.81, -450.03, -3435.03),
        rotation: new THREE.Vector3(2.69, 0.70, -2.83),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(715.19, -464.55, -3443.49),
        rotation: new THREE.Vector3(2.65, 0.72, -3.05),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(751.73, -484.19, -3471.73),
        rotation: new THREE.Vector3(2.98, 0.85, 2.89),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(788.31, -502.24, -3512.98),
        rotation: new THREE.Vector3(2.89, 0.52, 2.97),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(808.32, -513.89, -3564.37),
        rotation: new THREE.Vector3(3.09, 0.32, 2.89),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(810.81, -531.67, -3617.28),
        rotation: new THREE.Vector3(2.83, -0.22, 2.94),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(793.12, -554.41, -3663.07),
        rotation: new THREE.Vector3(2.80, -0.41, 3.03),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(772.68, -574.62, -3702.78),
        rotation: new THREE.Vector3(2.80, -0.41, 3.08),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(759.51, -594.37, -3747.10),
        rotation: new THREE.Vector3(2.82, -0.21, 3.09),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      },
      {
        offset: new THREE.Vector3(748.67, -610.89, -3794.21),
        rotation: new THREE.Vector3(3.03, -0.09, 3.11),
        scale: new THREE.Vector3(1, 1, 1),
        virtual: true
      }
    ],
    layer: 0,
    maxVisibleObjects: 5,
    lastVisibleObjectIndex: 0,
    nextIndex: 5
  }]
])
