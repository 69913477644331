import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface ShinyTextsState {
  texts: {name: string, active: boolean}[]
}

const initialState = () => ({
  texts: []
})

const shinyTextsState: Module<ShinyTextsState, RootState> = {
  state: initialState(),
  getters: {
    getShinyTextsState: (moduleState: ShinyTextsState) => moduleState
  },
  mutations: {
    RESET: (moduleState: ShinyTextsState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: ShinyTextsState, newState: ShinyTextsState) => {

      moduleState.texts = newState.texts

    },
    SET_TEXT_BY_NAME:
        (moduleState: ShinyTextsState, newText: {name: string, active: boolean}) => {

          const index = moduleState.texts.findIndex((text) => {

            return text.name === newText.name

          })
          if (index >= 0) {

            moduleState.texts[index] = newText

          }

        },
    ADD_TEXT:
        (moduleState: ShinyTextsState, newText: {name: string, active: boolean}) => {

          moduleState.texts.push(newText)

        }
  },
  namespaced: true
}

export default shinyTextsState
