/**
 * Mena sekcie
 */
export enum SectionNames {
  startSection = 'startDownhill',
  mobileSidePick = 'mobileSidePick',
  startSectionSecond = 'startDownhillSecond',
  startSectionThird = 'startDownhillThird',
  startSectionFourth = 'startDownhillFourth',
  startSectionFifth = 'startDownhillFifth',
  startSectionSixth = 'startDownhillSixth',
  startSectionSeventh = 'startDownhillSeventh',
}

type NameKeys = keyof typeof SectionNames;
type NameKeyFields = { [key in NameKeys]: string }

export interface UiState extends NameKeyFields {
  NameKeyFields: () => void
}

export enum TutorialEventType {
  gateMissEvent = 'gateMissEvent',
  sharpTurnInfo = 'sharpTurnInfo',
  fenceEvent = 'fenceEvent',
  finishEvent = 'finishEvent',
  awaitingEvent = 'awaitingEvent',
  waitingForStartEvent = 'waitingForStartEvent'
}

export enum TutorialObjectiveIds {
  sharpTurn = 'tutorialTask6-3',
  gates = 'tutorialTask6-2',
  start = 'tutorialTask6-1'
}
