import type { Module } from 'vuex'
import type { RootState } from '../index'

export interface SharpTurnState {
  isSharpTurn: boolean,
  active: boolean
}

const initialState = () => ({
  isSharpTurn: false,
  active: false
})

const sharpTurnState: Module<SharpTurnState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getInputsState: (moduleState: SharpTurnState) => moduleState,
    isSharpTurn: (moduleState: SharpTurnState) => moduleState.isSharpTurn
  },

  mutations: {
    RESET: (moduleState: SharpTurnState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: SharpTurnState, newState: SharpTurnState) => {

      moduleState.isSharpTurn = newState.isSharpTurn

    },
    SET_ACTIVE: (moduleState: SharpTurnState, active: boolean) => {

      moduleState.active = active

    }
  }
}

export default sharpTurnState
