/** Nazvy zvukov */
export enum AudioNames {

  spriteCommentators = 'commentators',
  spriteBells = 'bells',
  spriteNotLooped = 'notLooped',

  audienceNoise = 'audience_noise_var_02',
  audienceHype = 'audience_hype',
  audienceSad = 'audience_sad',
  audienceYay = 'audience_yay',
  audienceBells = 'bells_var02',
  audienceBells1 = 'bells_var021',
  countdownBeepLong = 'countdown_beep_long',
  countdownBeepShort = 'countdown_beep_short',
  skiingLoop = 'DH_skiing_loop',
  skiingCorner = 'DH_skiing_corner',
  skiingStart = 'BI_skiing_run_fastpace_all',
  skiingJump = 'DH_skiing_jump',
  skiingLanding = 'DH_skiing_landing',
  skiingBreak = 'DH_skiing_break',
  checkpointGood = 'DH_checkpoint_good',
  checkpointFail = 'DH_checkpoint_fail',
  crash = 'DH_crash',
  gateTouch = 'DH_gatepass_var03',
  gateTouch1 = 'DH_gatepass_var031',
  gateTouch2 = 'DH_gatepass_var032',
  gateTouch3 = 'DH_gatepass_var033',
  wind = 'SJ_wind',
  voiceStart = 'voice_start_var02',
  voiceStart1 = 'voice_start_var021',
  voiceStart2 = 'voice_start_var022',
  voiceStart3 = 'voice_start_var023',
  voiceStart4 = 'voice_start_var024',

  commentAfterStart = 'after_start',
  commentAfterStart2 = 'after_start2',
  commentBeforeFinish = 'before_finish',
  commentBeforeFinish2 = 'before_finish2',
  commentFinalResults = 'final_results-new',
  commentFinalResults2 = 'final_results-new2',
  commentFinish1 = 'finish1',
  commentFinish2 = 'finish2',
  commentFinish3 = 'finish3',
  commentFinish4 = 'finish4',
  commentIntro = 'intro',
  commentIntro2 = 'intro2',
  commentMissedGate = 'missed_gate',
  commentMissedGate2 = 'missed_gate2',
  commentSplitTimesFirstMinus = 'split_times_first_minus',
  commentSplitTimesFirstMinus2 = 'split_times_first_minus2',
  commentSplitTimesFirstPlus = 'split_times_first_plus',
  commentSplitTimesFirstPlus2 = 'split_times_first_plus2',
  commentSplitTimesDeficitIncreased = 'split_times_deficit_increased',
  commentSplitTimesDeficitIncreased2 = 'split_times_deficit_increased2',
  commentSplitTimesDeficitDecreased = 'split_times_deficit_decreased',
  commentSplitTimesDeficitDecreased2 = 'split_times_deficit_decreased2',
  commentSplitTimesFromGreenToRed = 'split_times_from_green_to_red',
  commentSplitTimesFromGreenToRed2 = 'split_times_from_green_to_red2',
  commentSplitTimesFromRedToGreen = 'split_times_from_red_to_green',
  commentSplitTimesFromRedToGreen2 = 'split_times_from_red_to_green2',
  commentSplitTimesLeadIncrease = 'split_times_lead_increase',
  commentSplitTimesLeadIncrease2 = 'split_times_lead_increase2',
  commentSplitTimesLeadDecrease = 'split_times_lead_decrease',
  commentSplitTimesLeadDecrease2 = 'split_times_lead_decrease2',

}

/** Kategorie zvukov */
export enum AudioCategories {

  misc = 'misc',
  audience = 'audience',
  countdown = 'countdown',
  checkpoint = 'checkpoint',
  skiing = 'skiing',
  start = 'start',
  gates = 'gates',
  commentators = 'commentators',
  sprite = 'sprites'

}

/** skupiny zvukov */
export enum AudioGroups {

  commentators = 'commentators'

}
