import type { LoaderDataTypes } from '@powerplay/core-minigames'
import { checkpointManager } from '../modes/training/CheckpointsManager'
import { ModelsNames } from '../types'
import { pathAssets } from '@/globals/globalvariables'

// Hlavny priecinok s modelmi
const modelsDir = `${pathAssets}/models/`

/**
 * Konfig pre modely
 */
export const modelsConfig: LoaderDataTypes = {
  [ModelsNames.skier]: {
    ext: 'glb',
    version: 10,
    genderActive: true,
    femaleVersion: 8,
    dir: modelsDir,
    mainMeshNames: [ModelsNames.skier]
  },
  [ModelsNames.hill]: {
    ext: 'glb',
    dir: modelsDir,
    version: 49,
    mainMeshNames: ['Physics_Track']
  },
  [ModelsNames.checkpoint]: {
    ext: 'glb',
    version: 7,
    dir: modelsDir,
    mainMeshNames: [checkpointManager.CHECKPOINT_MESH_NAME],
    isTrainingModel: true
  },
  [ModelsNames.gates]: {
    ext: 'glb',
    dir: modelsDir,
    useIfBatchingDisabled: true,
    version: 10
  },
  [ModelsNames.gatesLeft]: {
    ext: 'glb',
    dir: modelsDir
  },
  [ModelsNames.gatesRight]: {
    ext: 'glb',
    dir: modelsDir,
    version: 2
  },
  // [ModelsNames.cameraBoxes]: {
  //   ext: 'glb',
  //   version: 2,
  //   dir: modelsDir
  // }
}
