<template>
  <section class="positioner">
    <section
      v-if="buttonsVisible && showMovementControl && isControlsTypeAutoTuck"
    >
      <movement-sides
        :key="sharpTurnState.active"
        :disabled="isDisabled"
        :show-inner-buttons="sharpTurnState.active"
        :right-text="$t('utSharpRight')"
        :left-text="$t('utSharpLeft')"
        @position="changeMovement"
        @end-contact="endContact"
        @position-inner="changeMovementSharp"
        @end-contact-inner="endContactSharp"
      />
    </section>
    <section
      v-if="buttonsVisible && !(isControlsTypeAutoTuck && showMovementControl)"
    >
      <section
        v-if="!gameSettingsState.isLeft"
        class="flex justify-between right"
        :style="`transform: scale(${scaleCoef})`"
      >
        <section v-show="!showMovementControl" />
        <section
          v-if="startPhaseState.showBar"
          class="relative"
        >
          <action-button
            :disabled="isDisabled"
            :is-scaled="false"
          />
          <start-efficiency-bar
            :is-scaled="false"
          />
        </section>
      </section>
      <section
        v-else
        class="flex justify-between left"
        :style="`transform: scale(${scaleCoef})`"
      >
        <section
          v-if="startPhaseState.showBar"
          class="relative"
        >
          <start-efficiency-bar
            :is-scaled="false"
            :is-left="gameSettingsState.isLeft"
          />
          <action-button
            :disabled="isDisabled"
            :is-scaled="false"
          />
        </section>
      </section>
    </section>
  </section>
</template>

<script lang="ts">
import ActionButton from './ActionButton.vue'
import { mapGetters } from 'vuex'
import StartEfficiencyBar from './StartEfficiencyBar.vue'
import {
  MovementSides,
  WindowAspect
} from '@powerplay/core-minigames-ui'
import { inputsManager } from '@powerplay/core-minigames'
import { tutorialObjectives } from '@/app/modes/tutorial/TutorialObjectives'
import { TutorialObjectiveIds } from '@/app/types'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MobileInputs',
  components: {
    StartEfficiencyBar,
    ActionButton,
    MovementSides
  },
  mixins: [WindowAspect],
  computed: {
    ...mapGetters({
      isDisabled: 'InputsState/getDisabled',
      buttonsVisible: 'InputsState/getIsVisible',
      showMovementControl: 'ActionButtonState/getShowMovementControl',
      startPhaseState: 'StartPhaseState/getStartPhaseState',
      gameSettingsState: 'GameSettingsState/getGameSettingsState',
      sharpTurnState: 'SharpTurnState/getInputsState'
    }),
    isControlsTypeAutoTuck() {

      return true

    }
  },
  methods: {
    endContact() {

      this.$store.commit('MovementState/SET_POSITION_X', 0)

      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    },
    changeMovement(positionX: number) {

      if (this.disabled) return

      this.$store.commit('MovementState/SET_POSITION_X', positionX)

    },
    changeMovementSharp(positionX: number) {

      if (this.disabled) return

      this.$store.commit('SharpTurnState/SET_STATE', {
        isSharpTurn: true
      })
      this.changeMovement(positionX)
      tutorialObjectives.passObjective(TutorialObjectiveIds.sharpTurn)
      tutorialFlow.sharpTurnUsed = true

    },
    endContactSharp() {

      this.$store.commit('SharpTurnState/SET_STATE', {
        isSharpTurn: false
      })
      this.endContact()

    }
  }
})

</script>

<style lang="less" scoped>
.positioner {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.relative {
  position: relative;
}

.left {
  transform-origin: bottom left;
}

.right {
  transform-origin: bottom right;
}
</style>
