/* eslint-disable max-len */
<template>
  <section
    class="tutorial"
    :class="isMobile() ? 'mobile-overlay' : ''"
  >
    <!-- overlay -->
    <!-- Textbox a whatnot -->
    <!-- v-if podla use case -->
    <tutorial-tasks
      v-if="showObjectives"
      :tasks="tasks"
    />
    <tutorial-overlay
      v-if="
        tutorialState.anne.showAnne || tutorialState.tutorialMessage.showMessage
      "
      @click.prevent=""
      @mousedown.prevent=""
      @mouseup.prevent=""
      @touchstart.prevent=""
      @touchend.prevent=""
    />
    <tutorial-hand-settings
      v-if="tutorialState.mobile.show"
      style="pointer-events: auto;"
      :img-url="'https://appspowerplaymanager.vshcdn.net/images/winter-sports/minigame/'
        + 'giant-slalom/ui/tutorial/MOBIL_GS.jpg'"
      @mousedown.stop=""
      @mouseup.stop=""
      @touchstart.stop=""
      @touchend.stop=""
      @click-continue="continueTask"
    >
      <div class="mobiler">
        <div class="buttons">
          <div
            class="button-group"
            :class="{ reverse: !gameSettingsState.isLeft }"
          >
            <img
              width="150"
              height="150"
              :src="`${pathAssets}/ui/tutorial/button_gs.png`"
              alt="button2"
            >
          </div>
        </div>
      </div>
    </tutorial-hand-settings>
    <tutorial-anne
      v-if="tutorialState.anne.showAnne"
      :is-right="isAnneRight()"
    />
    <tutorial-message-box
      v-if="tutorialState.tutorialMessage.showMessage"
      :key="typeWrite"
      :type-write="typeWrite"
      :class="{ offsetBot: tutorialState.tutorialMessage.offset }"
      :header-text="$t('anne')"
      :text="$t(tutorialState.tutorialMessage.message)"
      :color="tutorialState.tutorialMessage.color"
      :button-yellow-text="yellowText"
      @show-full-text="setTypewriteToInactive"
    />
    <tutorial-button-start v-if="tutorialState.showButtonStart && isMobile()" />
    <tutorial-buttons-movement
      v-if="(tutorialState.showButtonsMovement || tutorialState.showSharpMovement) && isMobile()"
    />
    <div
      v-if="!isMobile() && tutorialState.showBarStart"
      class="nomobile-start-bar"
    >
      <start-efficiency-bar
        :forced-size="100"
        glow
      />
    </div>
    <tutorial-info-keys v-if="!isMobile()" />
  </section>
</template>

<script lang="ts">
import {
  WindowAspect,
  TutorialAnne,
  TutorialMessageBox,
  TutorialTasks,
  TutorialOverlay,
  TutorialHandSettings
} from '@powerplay/core-minigames-ui'
import { mapGetters } from 'vuex'
import {
  settings,
  tutorialManager
} from '@powerplay/core-minigames'
import type { TutorialObjective } from '@powerplay/core-minigames'
import { TutorialObjectiveIds } from '@/app/types'
import TutorialButtonStart from '@/components/Tutorial/TutorialButtonStart.vue'
import TutorialButtonsMovement from '@/components/Tutorial/TutorialButtonsMovement.vue'
import TutorialInfoKeys from '@/components/Tutorial/TutorialInfoKeys.vue'
import StartEfficiencyBar from '@/components/Inputs/StartEfficiencyBar.vue'
import { pathAssets } from '@/globals/globalvariables'
import { gatesConfig } from '@/app/config'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TutorialLogicComponent',
  components: {
    TutorialAnne,
    TutorialMessageBox,
    TutorialTasks,
    TutorialOverlay,
    TutorialButtonStart,
    TutorialButtonsMovement,
    TutorialInfoKeys,
    StartEfficiencyBar,
    TutorialHandSettings
  },
  mixins: [WindowAspect],
  data() {

    return {
      tasks: [],
      pathAssets
    }

  },
  computed: {
    ...mapGetters({
      // TODO: toto podla use case
      tutorialState: 'TutorialState/getTutorialState',
      gateSuccessCounter: 'GatesState/getSuccessCounter',
      tutorialObjectives: 'TutorialState/getTutorialObjectives',
      gameSettingsState: 'GameSettingsState/getGameSettingsState',
      startPhaseState: 'StartPhaseState/getStartPhaseState',
      typeWrite: 'TutorialCoreState/getTypeWriter',
      showObjectives: 'TutorialState/getShowTutorialObjectives'
    }),
    yellowText() {

      if (this.isMobile()) return this.$t('tapToContinue')
      return this.$t('clickToContinueTemp')

    }
  },

  watch: {
    tutorialState: {
      immediate: true,
      deep: true,
      handler() {

        this.updateTasks()

      }
    },
    gateSuccessCounter: {
      handler() {

        this.updateTasks()

      }
    }
  },
  methods: {
    setTypewriteToInactive() {

      this.$store.commit('TutorialCoreState/SET_TYPE_WRITER', false)
      tutorialManager.setTypeWriting(false)

    },
    tutorialAction() {

      console.log('click')
      // tutorialEventManager.emitNextEvent()

    },
    updateTasks() {

      const successCountCapped = this.gateSuccessCounter <= gatesConfig.tutorialGatesObjective ?
        this.gateSuccessCounter :
        gatesConfig.tutorialGatesObjective

      this.tasks = this.tutorialObjectives.map((objective: TutorialObjective) => {

        let color = 'blue'
        if (objective.passed) color = 'green'
        if (objective.failed) color = 'red'
        return {
          color,
          text: `${this.$t(objective.name)} ${
            objective.name === (TutorialObjectiveIds.gates as string) ?
              `${successCountCapped}/${gatesConfig.tutorialGatesObjective}` :
              ''
          }`
        }

      })

    },
    isAnneRight(): boolean {

      if (this.gameSettingsState.isLeft) {

        return !this.tutorialState.anne.isRight

      }
      return this.tutorialState.anne.isRight

    },
    continueTask() {

      this.setThings()
      settings.saveValues()
      window.dispatchEvent(new Event('mousedown'))

    },
    setThings(): void {

      settings.update('quality', this.gameSettingsState.graphicsSettings)
      settings.update('sounds', this.gameSettingsState.volume ? 1 : 0)
      settings.update('qualityAuto', this.gameSettingsState.graphicsAuto ? 1 : 0)
      settings.update('isLeft', this.gameSettingsState.isLeft ? 1 : 0)

    }
  }
})
</script>

<style lang="less">
.tutorial {

    &.mobile-overlay {
        .tutorial-anne, .tutorial-mobil, .tutorial-hand-bottom {
            position: fixed;
        }
    }

  .tutorial-tasks {
    position: absolute;
    left: 1%;
    top: 17%;
  }
  .tutorial-anne {
    bottom: 0;
  }
  .offsetBot {
    bottom: 40%;
  }

  .nomobile-start-bar {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 375px;
    height: 375px;
  }
}
.mobiler {
    height: 100%;

    .buttons {
        position: relative;
        width: 100%;
        height: 100%;

        .button-group {
            position: absolute;
            bottom: 130px;
            left: 130px;
            right: 130px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &.reverse {
                flex-direction: row-reverse;
            }
        }
    }
}

</style>
