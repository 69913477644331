import { createStore } from 'vuex'

import { CoreState } from '@powerplay/core-minigames-ui'
import GatesState from './modules/gatesState'
import MainState from './modules/mainState'
import StartPhaseState from './modules/startPhaseState'
import TimeState from './modules/timeState'
import DirectionsState from './modules/directionsState'
import InputsState from './modules/inputsState'
import InstructionsState from './modules/instructionsState'
import SplitTimeState from './modules/splitTimeState'
import UiState from './modules/uiState'
import TableState from './modules/tableState'
import ActionButtonState from './modules/actionButtonState'
import StartMessageState from './modules/startMessageState'
import MovementState from './modules/movementState'
import GameplayTableState from './modules/gameplayTableState'
import DebugState from './modules/debugState'
import SpeedmeterState from './modules/speedmeterState'
import TrainingState from './modules/trainingState'
import TrainingResultsState from './modules/trainingResultsState'
import TutorialState from './modules/tutorialState'
import FinishTopBoxState from './modules/finishTopBoxState'
import BlurState from './modules/blurState'
import SharpTurnState from './modules/sharpTurnState'
import TuckState from './modules/tuckState'
import ShinyTextsState from './modules/shinyTextsState'

// TODO: Check out why we need this?
// eslint-disable-next-line
export interface RootState {}

const modules = {
  ...CoreState,
  TimeState,
  MainState,
  StartPhaseState,
  GatesState,
  DirectionsState,
  InputsState,
  SplitTimeState,
  UiState,
  TableState,
  ActionButtonState,
  InstructionsState,
  StartMessageState,
  MovementState,
  GameplayTableState,
  DebugState,
  SpeedmeterState,
  TrainingState,
  TrainingResultsState,
  TutorialState,
  FinishTopBoxState,
  BlurState,
  SharpTurnState,
  TuckState,
  ShinyTextsState
}

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
    clearStateAll({ dispatch }) {

      dispatch('holyHandGrenade')

    },
    holyHandGrenade({ commit }) {

      for (const currentModule in modules) {

        if (['GameSettingsState', 'FpsLookerState', 'MobileState'].includes(currentModule)) continue
        commit(`${currentModule}/RESET`)

      }

    }
  },
  modules
})
