<template>
  <time-keeper-expanded
    v-if="uiState.showTimeKeeper"
    style="top:0; right:0;"
    class="time-keeper"
    :show-diff="showDiff"
    :is-v1="timeState.isV1"
    :v2-expanded="timeState.v2Expanded"
    :player1="player1"
    :player2="player2"
  />
</template>

<script lang="ts">
import {
  TimeKeeperExpanded,
  WindowAspect
} from '@powerplay/core-minigames-ui'
import { mapGetters } from 'vuex'
import {
  minigameConfig,
  playersManager,
  timeManager
} from '@powerplay/core-minigames'
import { defineComponent } from 'vue'
interface DataInterface {
  time: string
  player1: PlayerInterface
  player2: PlayerInterface
  frameAfterPauseTime: boolean
}
interface PlayerInterface {
  name?: string
  countryString?: string
  time?: string
  country?: string,
  position?: string,
  diff?: string,
  diffColor?: string
}
export default defineComponent({
  name: 'TimeKeeperComponent',
  components: {
    TimeKeeperExpanded
  },
  mixins: [WindowAspect],
  data(): DataInterface {

    return {
      time: '',
      player1: {
        name: '',
        countryString: '',
        country: ''
      },
      player2: {
        name: '',
        countryString: '',
        country: ''
      },
      frameAfterPauseTime: true
    }

  },
  computed: {
    ...mapGetters({
      timeState: 'TimeState/getTimeState',
      actualTime: 'TimeState/getTime',
      uiState: 'UiState/getUiState',
      splitTimes: 'SplitTimeState/getSplitTimes',
      isNoSplitTime: 'SplitTimeState/getNoSplitTimes'
    }),
    showDiff() {

      // eslint-disable-next-line
            // @ts-ignore
      if (this.timeState.bestTime === minigameConfig.dnfValue) return false
      if (this.isNoSplitTime) return false
      // eslint-disable-next-line
            // @ts-ignore
      return this.timeState.showDiff

    }
  },
  watch: {
    actualTime: {
      immediate: true,
      deep: true,
      handler() {

        this.getPlayer1()
        this.getPlayer2()

      }
    }
  },
  methods: {
    getPlayerPosition() {

      if (this.timeState.isFinish) {

        return String(playersManager.getPlayerActualPosition())

      }
      return ''

    },
    getPlayer1() {

      if (this.timeState.doTimeUpdate || this.frameAfterPauseTime) {

        this.time = this.timeState.timeWithPenalty

        if (!this.timeState.doTimeUpdate) {

          this.frameAfterPauseTime = false

          this.time = this.actualTime ?
            this.actualTime.toFixed(2) :
            this.time

          this.time = timeManager.getTimeInFormatFromSeconds(Number(this.time))

        } else {

          this.frameAfterPauseTime = true
          this.time = this.time.slice(0, -1)

        }

      }

      const diff = this.splitTimes?.[this.timeState.diffIndex]?.text
      const diffColor = this.splitTimes?.[this.timeState.diffIndex]?.color
      this.player1 = {
        name: playersManager.players?.[0]?.name ?? '',
        country: playersManager.players?.[0]?.country.toLowerCase() ?? '',
        countryString: playersManager.players?.[0]?.countryString ?? '',
        position: this.getPlayerPosition(),
        time: diff === '' ? '' : this.time,
        diff,
        diffColor
      }

    },
    getPlayer2() {

      if (!this.timeState.v2Expanded) {

        this.player2 = {
          name: '',
          countryString: '',
          country: ''
        }
        const bestTime = this.timeState.bestTime
        if (bestTime && bestTime !== minigameConfig.dnfValue) {

          this.player2.time = timeManager.getTimeInFormatFromSeconds(this.timeState.bestTime)

        }
        if (this.isNoSplitTime) this.player2.time = ''

      } else if (this.player2.name === '') {

        const player2 = playersManager.getBestResultPlayerInfo()

        this.player2 = {
          name: player2?.name ?? '',
          countryString: player2?.countryString ?? '',
          country: player2?.country.toLowerCase() ?? ''
        }

        if (player2?.finalResult && player2?.finalResult !== minigameConfig.dnfValue) {

          this.player2.time = timeManager.getTimeInFormatFromSeconds(player2.finalResult)

        }

      }

    },
  }

})
</script>

  <style lang="less" scoped>
  .time-keeper {
      position: absolute;
      top: 17%;
      right: 1%;
  }
  </style>
