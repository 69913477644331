import { gatesConfig } from '@/app/config'
import type { StartPhaseManager } from '@/app/phases/StartPhaseManager'
import store from '@/store'
import {
  tutorialManager,
  TutorialSectionType,
  TutorialMessageColors,
  game,
  modes,
  MobileDetector,
  requestManager
} from '@powerplay/core-minigames'
import { gatesManager } from '../../GatesManager'
import { disciplinePhasesManager } from '../../phases/DisciplinePhasesManager'
import {
  SectionNames,
  TutorialEventType,
  TutorialObjectiveIds,
  DisciplinePhases
} from '../../types'
import { tutorialObjectives } from './TutorialObjectives'
import { tutorialUIChange } from './TutorialUIChange'

/**
 *  Tutorial tasky ktore maju aj logiku v sebe na ovladanie tej ktorej udalosti
 */
export class TutorialFlow {

  /** aktualny typ eventu */
  private activeEventType = TutorialEventType.awaitingEvent

  /** ci sa triggerol event gateMiss alebo finish  */
  private gateEventTriggered = false

  /** ci sa triggerol event plotu */
  public fenceEventsTriggered = false

  /** ci sa triggerol event ostrych zatacok */
  public sharpTurnInfoTriggered = false

  /** ci pouzil ostre zatocenie */
  public sharpTurnUsed = false

  /** Fix of flow at tutorial end  */
  private final = false

  /** semafor pre sharp turn event */
  private delaySharpTurn = false

  /**
   * Inicializacia
   */
  public init(): void {

    const objectives = [
      {
        id: TutorialObjectiveIds.start as string,
        passed: false,
        failed: false,
        name: 'tutorialTask6-1'
      },
      {
        id: TutorialObjectiveIds.gates as string,
        passed: false,
        failed: false,
        name: 'tutorialTask6-2'
      },
      {
        id: TutorialObjectiveIds.sharpTurn as string,
        passed: false,
        failed: false,
        name: 'tutorialTask6-3'
      }
    ]
    tutorialObjectives.setObjectives(objectives)
    const tutorialSections = [
      {
        name: SectionNames.mobileSidePick,
        id: 0,
        type: TutorialSectionType.storyInput,
        sectionLogicIntro: () => {

          store.commit('InputsState/SET_VISIBLE', false)

        },
        sectionLogicFinish: () => {

          // document.getElementsByTagName('canvas')[0].classList.remove('blur-class')
          store.commit('BlurState/SET_IS_ACTIVE', false)
          store.commit('TutorialState/SET_SHOW_OBJECTIVES', true)
          store.commit('InputsState/SET_VISIBLE', true)

        }
      },
      {
        name: SectionNames.startSection,
        id: 1,
        type: TutorialSectionType.storyInput
      },
      {
        name: SectionNames.startSectionSecond,
        id: 2,
        type: TutorialSectionType.storyInput,
        sectionLogicIntro: () => {

          const startPhaseManager = disciplinePhasesManager
            .getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager
          startPhaseManager.showBarTutorial()

        },
        sectionLogicFinish: () => {

          store.commit('TutorialState/SET_SHOW_BUTTON_START', false)
          const startPhaseManager = disciplinePhasesManager
            .getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager
          startPhaseManager.launchSystem()

        }
      },
      {
        name: SectionNames.startSectionThird,
        id: 3,
        type: TutorialSectionType.gameButton
      },
      {
        name: SectionNames.startSectionFourth,
        id: 4,
        type: TutorialSectionType.storyInput,
        sectionLogicFinish: () => {

          store.commit('TutorialState/SET_SHOW_BUTTONS_MOVEMENT', false)

        }
      },
      {
        name: SectionNames.startSectionFifth,
        id: 5,
        type: TutorialSectionType.gameEvent,
        sectionLogicIntro: () => {

          /*
           * spusti sa detekcia neprejdenia branky a naraz do barier
           * plus detekcia prejdenia 4 brankami
           * podla toho text
           * plus detekujem prechod poslednou brankou, kedy posunieme task
           */

        },
        sectionLogicFinish: () => {

          // if (gameStats.wasExitedGame()) {

          /*
           *     requestManager.redirect()
           *     return
           */

          // }

          game.prematureFinishGame(disciplinePhasesManager.disciplinePrematureEnd)

          store.commit('LoadingCircleState/SET_STATE', {
            isActive: true
          })

        }
      }
    ]

    tutorialManager.setTutorialSections(tutorialSections)

    const firstTutorialStrings = ['chooseFirstDisciplineReturnFromMinigame', 'chooseFirstDisciplineContinue']
    if (MobileDetector.isMobile() && firstTutorialStrings.includes(requestManager.TUTORIAL_ID ?? '')) return

    tutorialManager.setActualSectionId(1)

  }

  /**
   * Public metoda do game loopu
   */
  public update(): void {

    // this.checkInput()
    tutorialUIChange.update()
    tutorialObjectives.update()

  }

  /**
   * Skontrolovanie inputov
   */
  public checkInput(): void {

    if (TutorialSectionType.gameEvent === tutorialManager.getActualSectionType()) {

      this.eventActionPressed()

    }

  }

  public eventActionTrigger(eventType: TutorialEventType): void {

    if (!modes.isTutorial()) return

    if (
      this.activeEventType === TutorialEventType.gateMissEvent &&
            eventType === TutorialEventType.sharpTurnInfo &&
            !this.gateEventTriggered
    ) {

      this.delaySharpTurn = true
      return

    }
    this.activeEventType = eventType

    if (
      !this.sharpTurnInfoTriggered &&
            eventType === TutorialEventType.sharpTurnInfo &&
            !this.delaySharpTurn
    ) {

      store.commit('TutorialState/SET_SHOW_SHARP_MOVEMENT', true)
      store.commit('SharpTurnState/SET_ACTIVE', true)
      this.sharpTurnInfoTriggered = true
      game.pauseGame()
      tutorialUIChange.setMessage(true, 'tutorialText6-4', undefined, true)
      tutorialUIChange.setAnne(true)

    }

    if (!this.fenceEventsTriggered && eventType === TutorialEventType.fenceEvent) {

      this.fenceEventsTriggered = true
      game.pauseGame()
      tutorialUIChange.setMessage(true, 'tutorialText6-6')
      tutorialUIChange.setAnne(true)

    }
    if (eventType === TutorialEventType.gateMissEvent && !this.gateEventTriggered) {

      game.pauseGame()
      tutorialUIChange.setMessage(true, 'tutorialText6-5')
      tutorialUIChange.setAnne(true)

    }
    if (eventType === TutorialEventType.finishEvent) {

      this.gateEventTriggered = true
      game.pauseGame()

      if (gatesManager.successCounter < gatesConfig.tutorialGatesObjective) {

        tutorialUIChange.setMessage(true, 'tutorialText6-7', TutorialMessageColors.red)
        tutorialUIChange.setAnne(true)

      } else if (!this.sharpTurnUsed) {

        tutorialUIChange.setMessage(true, 'tutorialText6-8', TutorialMessageColors.red)
        tutorialUIChange.setAnne(true)

      } else {

        if (this.final) return
        this.final = true
        tutorialUIChange.setMessage(true, 'tutorialText6-9', TutorialMessageColors.green)
        tutorialUIChange.setAnne(true, false)

      }

    }

  }

  public eventActionPressed(): void {

    if (game.paused) game.resumeGame()
    tutorialUIChange.setMessage(false, '')
    tutorialUIChange.setAnne(false)

    if (this.activeEventType === TutorialEventType.finishEvent) {

      tutorialManager.nextSection()

    }

    if (
      this.activeEventType === TutorialEventType.gateMissEvent
    ) {

      this.gateEventTriggered = true
      if (this.delaySharpTurn) {

        this.delaySharpTurn = false
        this.activeEventType = TutorialEventType.awaitingEvent

        this.eventActionTrigger(TutorialEventType.sharpTurnInfo)
        return

      }

    }

    this.activeEventType = TutorialEventType.awaitingEvent
    store.commit('TutorialState/SET_SHOW_SHARP_MOVEMENT', false)

  }

  public finishAction(): void {

    this.eventActionTrigger(TutorialEventType.finishEvent)
    // disciplinePhasesManager.resetAttempt()

  }

}

export const tutorialFlow = new TutorialFlow()
