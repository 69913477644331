/** Mena vsetkych modelov, ktore sa pouzivaju v minihre */
export enum ModelsNames {
  skier = 'skier',
  hill = 'hill',
  checkpoint = 'marker',
  gates = 'gates',
  gatesPositions = 'Branky_Positions',
  gatesLeft = 'gatesLeft',
  gatesRight = 'gatesRight',
  cameraBoxes = 'cameraBoxesGS'
}

/** Mena vsetkych textur, ktore sa pouzivaju v minihre */
export enum TexturesNames {
  skierRacePrefix = 'skier_race_',
  skierRaceWhiteMan = 'male/skier_race_white',
  skierRaceMulattoMan = 'male/skier_race_mulatto',
  skierRaceBlackMan = 'male/skier_race_black',
  skierRaceWhiteWoman = 'female/skier_race_white',
  skierRaceMulattoWoman = 'female/skier_race_mulatto',
  skierRaceBlackWoman = 'female/skier_race_black',
  skierClothes = 'skier_clothes',
  hill = 'Atlas',
  transparent = 'TransparentTexture',
  mountain = 'mountain',
  skybox = 'Skybox',
  ads = 'logo',
  flags = 'flags',
  track = 'track',
  transparentSafetyNet = 'TransparentSafetyNet',
  people = 'People',
  lightmapHill = 'LightmapHill',
  staticPeople = 'StaticPeople',
  snowParticle = 'snowSSbase',
  smallSnow = 'snowDust',
  smokeScreen = 'testOpacity2',
  // smokeScreen = 'snowRed',
  banners = '3d-banners-v3',
  lakeIce = 'LakeIce',
  staticPersons = 'StaticPersons',
  gates = 'Gates',
  marker = 'marker',
  startCabin = 'StartCabin'
}

/** Mena vsetkych materialov, ktore sa pouzivaju v minihre */
export enum MaterialsNames {
  skier = 'skier',
  hill = 'Atlas1',
  transparent = 'TransparentTexture',
  mountain = 'mountain',
  ads = 'ads',
  flags = 'flags',
  track = 'track',
  transparentSafetyNet = 'TransparentSafetyNet',
  people = 'People',
  staticPeople = 'StaticPeople',
  onlyVertexColor = 'OnlyVertexColor',
  checkpointGlow = 'checkpointGlow',
  lakeIce = 'LAKEICE',
  gates = 'gates',
  startCabin = 'StartCabin'
}

/** Mena vsetkych animacii hraca */
export enum PlayerAnimationsNames {
  carve = 'carve',
  carveLeft = 'obluk L',
  carveRight = 'obluk R',
  jump = 'jump',
  prepare = 'prepare',
  skating = 'skating',
  preStart = 'prestart 2',
  start = 'start',
  tuck = 'tuck',
  tuckTurnLeft = 'tuck turn L',
  tuckTurnRight = 'tuck turn R',
  fall = 'fall 2',
  stop = 'stop',
  bad = 'bad',
  neutral = 'neutral',
  happy = 'happy'
}

export enum GateAnimationsNames {
  left = 'left',
  right = 'right',
  straight = 'straight',
  none = 'straight original'
}

/** Konfig pre zakladne fyzicke veci */
export interface GameConfig {
  maxSpeedNormal: number
  maxSpeedCrouch: number
  inAirSlowingCoef: number
  linearDamping: number
  playerMass: number
  frictionHillPlayer: number
  restitutionHillPlayer: number
  frictionEquationRelaxationHillPlayer: number
  frictionEquationStiffnessHillPlayer: number
  contactEquationRelaxationHillPlayer: number
  contactEquationStiffnessHillPlayer: number
}

/**
 * Specialne data z init requestu
 */
export interface SpecialDataFromInit {

  split: number[]

}
