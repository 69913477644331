export const checkPointsPlaceConfig = {
  TrackMarker1:
    {
      position: {
        x: 671.7615966796875,
        y: -248.4510040283203 + 3,
        z: -2878.987548828125
      },
      rotation: {
        _x: 0,
        _y: 0,
        _z: 0,
        _order: 'XYZ'
      }
    },
  TrackMarker2:
    {
      position: {
        x: 667.1815795898438,
        y: -270.1410217285156 + 3,
        z: -2931.947509765625
      },
      rotation: {
        _x: 0,
        _y: 0,
        _z: 0,
        _order: 'XYZ'
      }
    },
  TrackMarker3:
    {
      position: {
        x: 658.0315,
        y: -280.2610168457031 + 3,
        z: -2952.78759765625
      },
      rotation: {
        _x: 0,
        _y: 0,
        _z: 0,
        _order: 'XYZ'
      }
    },
  TrackMarker4:
    {
      position: {
        x: 678.1515502929688,
        y: -298.4210205078125 + 3,
        z: -2989.137451171875
      },
      rotation: {
        _x: 0,
        _y: 0,
        _z: 0,
        _order: 'XYZ'
      }
    },
  TrackMarker5:
    {
      position: {
        x: 702.071594238281,
        y: -311.5110168457031 + 3,
        z: -3021.867431640625
      },
      rotation: {
        _x: 0,
        _y: 0,
        _z: 0,
        _order: 'XYZ'
      }
    },
  TrackMarker6:
    {
      position: {
        x: 715.9415893554688,
        y: -319.2610168457031 + 3,
        z: -3063.527587890625
      },
      rotation: {
        _x: 0,
        _y: 0,
        _z: 0,
        _order: 'XYZ'
      }
    },
  TrackMarker7:
    {
      position: {
        x: 714.861572265625,
        y: -331.21099853515 + 3,
        z: -3119.057373046875
      },
      rotation: {
        _x: 0,
        _y: 0,
        _z: 0,
        _order: 'XYZ'
      }
    },
  TrackMarker8:
    {
      position: {
        x: 696.6015625,
        y: -341.281005859375 + 3,
        z: -3150.027587890625
      },
      rotation: {
        _x: 0,
        _y: 0,
        _z: 0,
        _order: 'XYZ'
      }
    },
  TrackMarker9:
    {
      position: {
        x: 694.841552734375,
        y: -350.9309997558594 + 3,
        z: -3181.447509765625
      },
      rotation: {
        _x: 0,
        _y: 0,
        _z: 0,
        _order: 'XYZ'
      }
    },
  TrackMarker10:
    {
      position: {
        x: 669.4715576171875,
        y: -361.4609985351562 + 3,
        z: -3218.267578125
      },
      rotation: {
        _x: 0,
        _y: 0,
        _z: 0,
        _order: 'XYZ'
      }
    },
  TrackMarker11:
    {
      position: {
        x: 636.1715698242188,
        y: -386.531005859375 + 3,
        z: -3288.58740234375
      },
      rotation: {
        _x: 0,
        _y: 0,
        _z: 0,
        _order: 'XYZ'
      }
    },
  TrackMarker12:
    {
      position: {
        x: 650.7515869140625,
        y: -416.4210205078125 + 3,
        z: -3358.95751953125
      },
      rotation: {
        _x: 0,
        _y: 0,
        _z: 0,
        _order: 'XYZ'
      }
    },
  TrackMarker13:
    {
      position: {
        x: 666.7615966796875,
        y: -439.7109985351562 + 3,
        z: -3404.427490234375
      },
      rotation: {
        _x: 0,
        _y: 0,
        _z: 0,
        _order: 'XYZ'
      }
    },
  TrackMarker14:
    {
      position: {
        x: 688.131591796875,
        y: -457.6910095214844 + 3,
        z: -3438.267578125
      },
      rotation: {
        _x: 0,
        _y: 0,
        _z: 0,
        _order: 'XYZ'
      }
    },
  TrackMarker15:
    {
      position: {
        x: 752.291564941406,
        y: -485.35101318359375 + 3,
        z: -3471.637451171875
      },
      rotation: {
        _x: 0,
        _y: 0,
        _z: 0,
        _order: 'XYZ'
      }
    },
  TrackMarker16:
    {
      position: {
        x: 793.4915771484375,
        y: -511.1310119628906 + 3,
        z: -3540.49755859375
      },
      rotation: {
        _x: 0,
        _y: 0,
        _z: 0,
        _order: 'XYZ'
      }
    },
  TrackMarker17:
    {
      position: {
        x: 810.9315795898438,
        y: -532.721008300781 + 3,
        z: -3618.48779296875
      },
      rotation: {
        _x: 0,
        _y: 0,
        _z: 0,
        _order: 'XYZ'
      }
    },
  TrackMarker18:
    {
      position: {
        x: 794.4515991210938,
        y: -554.221008300781 + 3,
        z: -3662.1474609375
      },
      rotation: {
        _x: 0,
        _y: 0,
        _z: 0,
        _order: 'XYZ'
      }
    },
  TrackMarker19:
    {
      position: {
        x: 757.1015625,
        y: -585.4210205078125 + 3,
        z: -3723.27783203125
      },
      rotation: {
        _x: 0,
        _y: 0,
        _z: 0,
        _order: 'XYZ'
      }
    },
  TrackMarker20:
    {
      position: {
        x: 748.071594238281,
        y: -612.3510131835938 + 3,
        z: -3802.45751953125
      },
      rotation: {
        _x: 0,
        _y: 0,
        _z: 0,
        _order: 'XYZ'
      }
    }
}
