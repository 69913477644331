import { ParticleEmitter } from '@powerplay/core-minigames'

export enum ParticleNames {
  whiteSnow = 'bielySneh',
  smokeScreenR = 'smokeScreenR',
  smokeScreenL = 'smokeScreenL',
  smallSnowL = 'smallSnowL',
  smallSnowR = 'smallSnowR'
}

export interface SnowState {
  active: boolean,
  particleAmountPerEmit: number
}

export type ParticleEmitters = Partial<Record<ParticleNames, ParticleEmitter>>

export enum SkiSide {
  left = 'L',
  right = 'R'
}
