/**
 * Konfig pre branky
 */
export const gatesConfig = {

  /** Pocet branok - posledna je ciel */
  count: 46,

  /** Medzicasy budu na brankach s tymito indexami (od 0 po count - 1) */
  split: [11, 23, 35],

  /** Branky, pri ktorych zacneme prehravat zvuky zvonov */
  audioGatesStartBells: [10, 22, 36, 43],

  /** Branky pri ktorych prestaneme prehravat zvuky zvonov */
  audioGatesStopBells: [11, 23, 35, 45],

  /* pocet branok na splnenie objective */
  tutorialGatesObjective: 15,

  /** po akych brankach sa zobrazi speedmeter */
  speedmeterOnGate: [],

  /** o kolko m sa ma zmensit trigger (mimo konecnej branky) */
  successTriggerWidth: 0.25,

  /** Kolkata branka pred cielom bude trigerovat audience hype */
  gateNumberTriggerAudienceHype: 5,

  /** debug veci pre triggery */
  debugTriggers: {

    /** Ci sa maju zobrazit debug vektory pre triggery branok */
    vectors: false,

    /** Ci sa maju zobrazit debug meshe pre triggery branok */
    meshes: false,

    /** Ci sa maju zobrazit debug body pre triggery branok */
    points: false

  }

}
