import type { AudioObject } from '@powerplay/core-minigames'
import {
  AudioCategories,
  AudioGroups,
  AudioNames
} from '../types'
import { spriteAudio } from './spriteAudioConfig'
/**
 * Trieda konfigu pre zvuky
 */
export const audioConfig: AudioObject[] = [

  ...spriteAudio,
  {
    files: [
      AudioNames.audienceHype
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: true,
    category: AudioCategories.audience,
    version: 4
  },
  {
    files: [
      AudioNames.audienceNoise
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: true,
    category: AudioCategories.audience
  },

  {
    files: [
      AudioNames.skiingLoop
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: true,
    version: 2,
    category: AudioCategories.skiing
  },

  {
    files: [
      AudioNames.wind
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 0.5,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: true,
    version: 2,
    category: AudioCategories.skiing
  },

  {
    files: [
      AudioNames.skiingCorner
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 0.3,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: true,
    category: AudioCategories.skiing
  },

  {
    files: [
      AudioNames.skiingStart
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1.5,
    rateRandom: 0,
    enabled: true,
    loop: true,
    version: 2,
    category: AudioCategories.skiing
  },

  {
    files: [
      AudioNames.voiceStart,
      AudioNames.voiceStart1,
      AudioNames.voiceStart2,
      AudioNames.voiceStart3,
      AudioNames.voiceStart4
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    category: AudioCategories.misc,
    loadRandomCount: 1
  },

  {
    files: [
      AudioNames.commentAfterStart,
      AudioNames.commentAfterStart2
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    category: AudioCategories.commentators,
    loadRandomCount: 1,
    group: AudioGroups.commentators,
    ignoreModes: [3, 14, 9, 10]
  },

  {
    files: [
      AudioNames.commentBeforeFinish,
      AudioNames.commentBeforeFinish2
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    category: AudioCategories.commentators,
    loadRandomCount: 1,
    group: AudioGroups.commentators,
    ignoreModes: [3, 14, 9, 10]
  },

  {
    files: [
      AudioNames.commentFinalResults,
      AudioNames.commentFinalResults2
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    category: AudioCategories.commentators,
    loadRandomCount: 1,
    group: AudioGroups.commentators,
    ignoreModes: [3, 14, 9, 10]
  },

  {
    files: [
      AudioNames.commentIntro,
      AudioNames.commentIntro2
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    category: AudioCategories.commentators,
    group: AudioGroups.commentators,
    loadRandomCount: 1,
    ignoreModes: [9, 10]
  },

]
