<template>
  <game-giant-slalom-component />
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import GameGiantSlalomComponent from './components/GameGiantSlalomComponent.vue'

export default defineComponent({
  components: {
    GameGiantSlalomComponent
  },
})

</script>
