<template>
  <game-loading
    v-if="show"
    :game-version="version"
  />
</template>

<script lang="ts">
import { GameLoading } from '@powerplay/core-minigames-ui'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LoadingComponent',
  components: { GameLoading },
  data() {

    return {
      show: true
    }

  },
  computed: {
    version() {

      return document.getElementById('live-layout')?.getAttribute('data-version') ?? ''

    }
  }
})

</script>
