/** Konfig pre nastavenie hraca a jeho ovladania */
export const unitCyclesConfig = {

  /** Veci pre jednotkovu kruznicu hracovych inputov */
  playerInputs: {

    /**
     * Rozsah na obe strany po jednotkovej kruznici. Zacina sa na 0, co je smer hore. Vieme ist
     * potom v rozsahu + a -, napr ak je 90 stupnov, tak vieme ist po -90 stupnov vlavo a
     * 90 stupnov vpravo
     * HODNOTA v radianoch
     */
    maxAngleSharp: Math.PI / 3,

    /**
     * Rozsah na obe strany po jednotkovej kruznici. Zacina sa na 0, co je smer hore. Vieme ist
     * potom v rozsahu + a -, napr ak je 90 stupnov, tak vieme ist po -90 stupnov vlavo a
     * 90 stupnov vpravo
     * HODNOTA v radianoch
     */
    maxAngleNormal: Math.PI / 4,

    /**
     * Ked ideme z hlbky jednej strany do druhej, tak si skratime "cestu"
     * HODNOTA v radianoch
     */
    maxAngleFast: Math.PI / 4,

    /**
     * Koeficient, ktory sa pridava kazdy 1 frame. Je to prirastok, napr ked sme na hodnote 0,
     * prirastok je 0.3 a mame stlacene inputy vpravo, tak sa hodnota meni po frameoch
     * nasledovne: 0 .. 0.3 .. 0.6 .. 0.9 az kym dosiahne maximum range, kde nepokracuje dalej
     * HODNOTA v radianoch
     */
    coefAdd: {
      /*
       * tieto hodnoty sluzia iba na vypocitanie coeficientu
       * vo vzorcoch kde ide este sila z atributu
       */
      normal: 0.04,
      sharp: 0.07
    },

    /**
     * Hodnota ktorou sa hráč otáča automaticky späť na center ak sa nedrží žiadne tlačidlo
     * HODNOTA v radianoch
     */
    coefReturnToCenter: 0.2,

    /**
     * Koeficient pre redistribuciu na x a z osi
     * HODNOTA v radianoch
     */
    coefDirectionChange: 0.08,

    /**
     * Koeficient pre redistribuciu na x a z osi
     * HODNOTA v radianoch
     */
    coefDirectionChangeSharp: 0.1

  },

  /** Veci pre silu, ktora pohana vpred */
  forwardForce: {

  }

}
